import warehouseScope from "@/core/services/tomoni/warehouse_scope.local";
export default {
  data() {
    return {
      labelMessage: {
        type: "SFA",
        palletId: "P15242135",
        sfaId: "2177456",
        locationId: "undefined",
        tracking: "84221677011",
        jancode: "undefined",
        sku: "123000",
        Command: "undefined",
        locationName: "truongdn",
        quantity: 1,
        startIndex: 1,
        itemQuantity: 0,
        sfaQuantity: 1,
        floor: "undefined",
        row: "undefined",
        region: "",
        column: "undefined",
        createdDate: "30-06-2022",
      },
      labelPrint: false,
    }
  },
  computed: {
    warehouse() {
      return this.$store.getters["warehouse.areas.list"];
    },
    message() {
      return {
        ...this.labelMessage,
        region: this.warehouse.find((w) => w.id == warehouseScope.get())?.name
      }
    }
  },
}
