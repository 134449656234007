import lodash from "@/core/plugins/lodash";
export default {
  data() {
    return {
      code: ""
    }
  },
  watch: {
    code: lodash.debounce(function (value) {
      if (value) this.code = ""
    }, 300),
  },
  methods: {
    checkInput(e) {
      if (!e) return false
      if (e.tagName == "INPUT" || e.tagName === 'TEXTAREA') return true
    },
    press(e) {
      if (this.checkInput(e.target)) return;
      if (e.key == "Enter") {
        if (this.code) {
          const code = this.code
          this.code = "";
          return code
        }
        return;
      }
      this.code += e.key;
    },
    focus() {
      const inputs = Array.from(document.querySelectorAll("input"));
      inputs[0].focus()
    },
  },
  mounted() {
    window.addEventListener("keypress", this.pickBarcode);
  },
  destroyed() {
    window.removeEventListener("keypress", this.pickBarcode);
  },
}
